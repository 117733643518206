<template>
  <div id="dashboard-nfe-tab">
    <div class="ds-header">
      <div class="ds-title">
        Dashboard
        <span id="help-icon" class="icon-box">
          <v-help-circle class="icon stroke" />
        </span>
        <b-tooltip target="help-icon" placement="bottom">
          Confira aqui os dados e resumos mais importantes da emissão de nota,
          utilize os filtros para personalizar sua visualização.
        </b-tooltip>
      </div>

      <div class="c-header-month form-group" @click="chevronRotate">
        <date-picker
          range
          id="period"
          v-model="period"
          format="DD [de] MMMM"
          :clearable="true"
          :lang="langDatePicker"
          @blur="resetChevronState"
          prefix-class="dx"
          class="dx-datepicker"
          placeholder="Selecione um intervalo"
          @change="updatePeriodSelected"
        >
          <template slot="icon-calendar">
            <div id="chevron">
              <ChevronDown class="datepicker-chevrondown-icon" />
            </div>
          </template>
        </date-picker>
      </div>
    </div>

    <div class="dashboard__content">
      <CardsSummary :dashboard="dashboardData" />

      <div class="graph__container mt-3">
        <div class="chart">
          <DonutChartComponent
            title="Controle de NFS-e"
            :colors="[
              'var(--light-blue)',
              'var(--neutral-500)',
              'var(--orange)'
            ]"
            :dashboard="dashboardData"
            v-if="dashboardData"
          />
        </div>
        <div>
          <NfFlowChart :dashboard="dashboardData" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentClinic } from '@/utils/localStorageManager'
import api from '@/modules/nfe/api'

const defaultPeriod = [
  new Date(new Date().getFullYear(), new Date().getMonth(), 1),
  new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
]

export default {
  components: {
    'v-help-circle': () => import('@/assets/icons/help-circle.svg'),
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    NfFlowChart: () => import('@/modules/nfe/components/NfFlowChart'),
    CardsSummary: () => import('@/modules/nfe/components/CardsSummary'),
    DonutChartComponent: () =>
      import('@/modules/nfe/components/DonutChartComponent')
  },
  async created() {
    await this.getDashboard()
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      chevronState: false,
      langDatePicker: {
        formatLocale: {
          weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']
        }
      },
      dashboardData: null,
      period: defaultPeriod
    }
  },
  methods: {
    chevronRotate() {
      if (this.chevronState === false) {
        const chevron = document.getElementById('chevron')
        chevron.classList.toggle('chevron-active')
        this.chevronState = true
      }
    },
    resetChevronState() {
      if (this.chevronState === true) {
        const chevron = document.getElementById('chevron')
        chevron.classList.toggle('chevron-active')
        this.chevronState = false
      }
    },
    async updatePeriodSelected($event) {
      if ($event[0] === null) {
        this.period = defaultPeriod
      } else {
        this.period = $event
      }

      const isLoading = this.$loading.show()

      try {
        await this.getDashboard()
      } catch (error) {
        console.error(error)
      } finally {
        isLoading.hide()
      }
    },
    async getDashboard() {
      const { data } = await api.getDashboard(
        this.clinic.id,
        this.period[0]?.toJSON(),
        this.period[1]?.toJSON()
      )
      this.dashboardData = data
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/main';
@import '@/assets/scss/dx-datepicker.scss';

#dashboard-nfe-tab {
  padding: 30px 10px;

  .ds-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
  }

  .ds-title {
    display: inline-flex;
    align-items: center;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;

    .icon {
      height: 24px;
      width: 24px;
      margin-left: 5px;
      stroke: var(--neutral-500);
    }
  }

  .c-header-month div {
    display: inline-flex;
    align-items: center;
    justify-content: end;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
    text-align: center !important;
  }

  .graph__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
  }

  .form-group {
    div {
      width: 100%;
    }

    .dx-datepicker {
      caret-color: transparent;

      .dx-input-wrapper {
        display: flex;
        justify-content: space-between;
        padding: 2px 4px;
        border: 1px solid var(--neutral-300);
        border-radius: 8px;

        input {
          width: 270px;
        }

        #chevron {
          cursor: pointer;
          transition: all 500ms;
        }

        .datepicker-chevrondown-icon {
          fill: transparent;
          stroke: var(--blue-500);
          height: 24px;
          width: 24px;
        }

        .chevron-active {
          transform: rotate(-180deg);
        }
      }
    }
  }

  @media (min-width: 1440px) {
    .icon {
      margin-left: 5px;
    }
  }
}
</style>
